import { getGlobalACPNamespace } from '@ac/library-utils/dist/utils';

export const getComponents = (): Promise<typeof import('./publicComponents')> =>
  import('./publicComponents');

const defineGlobalProperties = (): void => {
  getGlobalACPNamespace().getComponents = getComponents;
};

defineGlobalProperties();
